import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import { Container, Div } from "../styles/layout"
import { minMQ, maxMQ } from "../styles/helpers"

const Footer = () => {
  const { secondary, social } = useStaticQuery(graphql`
    query FooterMenusQuery {
      secondary: wpMenu(name: { eq: "Secondary" }) {
        menuItems {
          nodes {
            label
            target
            parentId
            id
            url
          }
        }
      }
      social: wpMenu(name: { eq: "Social" }) {
        menuItems {
          nodes {
            label
            target
            parentId
            id
            url
          }
        }
      }
    }
  `)

  return (
    <MainFooter>
      <Container>
        <FooterNavigation>
          <Div $touchWidth="50%">
            <Menu>
              {social.menuItems.nodes.map(menuItem =>
                !menuItem.target ? (
                  <MenuItem key={menuItem.id}>
                    <FooterLink to={menuItem.url} activeClassName="active">
                      {menuItem.label}
                    </FooterLink>
                  </MenuItem>
                ) : (
                  <MenuItem key={menuItem.id}>
                    <FooterLinkTarget
                      href={menuItem.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {menuItem.label}
                    </FooterLinkTarget>
                  </MenuItem>
                )
              )}
            </Menu>
          </Div>

          <Div $touchWidth="50%">
            <Menu>
              {secondary.menuItems.nodes.map(menuItem =>
                !menuItem.target ? (
                  <MenuItem key={menuItem.id}>
                    <FooterLink to={menuItem.url} activeClassName="active">
                      {menuItem.label}
                    </FooterLink>
                  </MenuItem>
                ) : (
                  <MenuItem key={menuItem.id}>
                    <FooterLinkTarget
                      href={menuItem.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {menuItem.label}
                    </FooterLinkTarget>
                  </MenuItem>
                )
              )}
            </Menu>
          </Div>
        </FooterNavigation>
      </Container>
    </MainFooter>
  )
}

export default Footer

const MainFooter = styled.footer`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.pilotBlack};

  ${maxMQ("touch")} {
    padding: 3rem 0 6rem;
  }

  ${minMQ("tablet")} {
    max-width: calc(100% - (${props => props.theme.navWidth}));
  }

  ${minMQ("desktop")} {
    padding: 1.5rem 0;
    padding: 4rem 0;
  }
`

const FooterNavigation = styled.div`
  display: flex;
  ${maxMQ("touch")} {
    column-gap: 3rem;
  }
  ${minMQ("desktop")} {
    justify-content: space-between;
  }
`

const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  ${minMQ("desktop")} {
    display: flex;
    column-gap: 3rem;
    row-gap: 0.8rem;
  }
`

const MenuItem = styled.li`
  color: ${props => props.theme.pilotWhite};
  font-size: 16px;
  font-family: ${props => props.theme.fontFamilySecondary};
  ${maxMQ("touch")} {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`

const FooterLink = styled(Link)`
  text-decoration-color: transparent;
  text-underline-offset: 0.125rem;
  font-family: ${props => props.theme.fontFamilySecondary};

  &:hover {
    ${minMQ("desktop")} {
      text-decoration-color: inherit;
    }
  }
`

const FooterLinkTarget = styled.a`
  text-decoration-color: transparent;
  text-underline-offset: 0.125rem;
  font-family: ${props => props.theme.fontFamilySecondary};

  &:hover {
    ${minMQ("desktop")} {
      text-decoration-color: inherit;
    }
  }
`
