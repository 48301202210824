import React from "react"
// import ReactDOM from "react-dom/client"
import Layout from "./src/components/layout"

// transition duration from `layout.js` * 1000 to get time in ms
// * 2 for exit + enter animation
const transitionDelay = 0.3 * 1000 * 1

// export const replaceHydrateFunction = () => {
//   return (element, container) => {
//     const root = ReactDOM.createRoot(container)
//     root.render(element)
//   }
// }

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}
