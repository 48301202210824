import React, { useContext, useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import Animation from "./animation"
import PilotLogo from "./logo"

import { theme } from "../styles/theme"
import { Section, Container, Div } from "../styles/layout"
import { minMQ, maxMQ } from "../styles/helpers"

import { GlobalContext } from "../../GlobalContext"

const Navigation = props => {
  const [bgColour, setBgColour] = useState(theme.pilotWhite)
  const [reachedBottom, setReachedBottom] = useState(false)
  const { menu, settings } = useStaticQuery(graphql`
    query NavigationQuery {
      menu: wpMenu(name: { eq: "Primary" }) {
        menuItems {
          nodes {
            label
            target
            parentId
            url
            id
          }
        }
      }
      settings: wp {
        siteSettings {
          acfOptionsSiteSettings {
            navigation {
              work {
                featuredWork {
                  ... on WpWork {
                    id
                    title
                    uri
                    acfPostTypeWork {
                      navigation {
                        work {
                          featuredWork {
                            icon {
                              localFile {
                                publicURL
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const featuredWork =
    settings?.siteSettings?.acfOptionsSiteSettings?.navigation?.work
      ?.featuredWork[0] || ``

  const {
    openOverlay,
    setOpenOverlay,
    openNav,
    setOpenNav,
    openPractice,
    setOpenPractice,
    openTeamMember,
    setOpenTeamMember,
    openNewBusiness,
    setOpenNewBusiness,
    teamMemberName,
    setTeamMemberName,
  } = useContext(GlobalContext)

  const toggleOverlay = () => {
    if (openPractice) {
      setOpenNav(false)
      setOpenPractice(false)
      setOpenTeamMember(false)
      setOpenNewBusiness(false)
      setOpenOverlay(false)
      setTeamMemberName(null)
    } else if (openTeamMember) {
      setOpenNav(false)
      setOpenPractice(false)
      setOpenTeamMember(false)
      setOpenNewBusiness(false)
      setOpenOverlay(false)
      setTeamMemberName(null)
      window.history.pushState(null, "", props.location.pathname)
    } else if (openNewBusiness) {
      setOpenNav(false)
      setOpenPractice(false)
      setOpenTeamMember(false)
      setOpenNewBusiness(false)
      setOpenOverlay(false)
      setTeamMemberName(null)
      window.history.pushState(null, "", props.location.pathname)
    } else if (openNav) {
      setOpenNav(false)
      setOpenPractice(false)
      setOpenTeamMember(false)
      setOpenNewBusiness(false)
      setOpenOverlay(false)
      setTeamMemberName(null)
    } else {
      setOpenNav(true)
      setOpenOverlay(true)
    }
  }

  const grandparent = {
    open: {
      x: 0,
      transition: {
        bounce: 0,
        delayChildren: 0.3,
      },
    },
    closed: {
      x: `100%`,
      transition: {
        bounce: 0,
        duration: 0.125,
      },
    },
  }

  const parent = {
    open: {
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
    closed: { opacity: 0 },
  }

  const child = {
    open: {
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
    closed: { opacity: 0 },
  }

  let label

  if (teamMemberName) {
    label = (
      <NavigationLabel>
        <span>
          <NavigationLabelParent
            to="/about/"
            aria-label="About"
            onClick={toggleOverlay}
          >
            About
          </NavigationLabelParent>
          <NavigationLabelDivider>|</NavigationLabelDivider>
        </span>
        <NavigationLabelPage>{teamMemberName}</NavigationLabelPage>
      </NavigationLabel>
    )
  } else {
    label = (
      <NavigationLabel>
        {props.parentTitle && props.parentLink && (
          <span>
            <NavigationLabelParent
              to={props.parentLink}
              aria-label={props.parentTitle}
            >
              {props.parentTitle}
            </NavigationLabelParent>
            <NavigationLabelDivider>|</NavigationLabelDivider>
          </span>
        )}
        <NavigationLabelPage>{props.pageTitle}</NavigationLabelPage>
      </NavigationLabel>
    )
  }

  useEffect(() => {
    if (typeof document !== `undefined`) {
      const handleScroll = () => {
        const offsetHeight = document.documentElement.offsetHeight
        const innerHeight = window.innerHeight
        const scrollTop = document.documentElement.scrollTop

        const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 520

        setReachedBottom(hasReachedBottom)
      }

      window.addEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (openOverlay) {
        document.body.classList.add("no-scroll")
      } else {
        document.body.classList.remove("no-scroll")
      }
    }

    if (typeof window !== "undefined") {
      window.onpopstate = () => {
        setOpenNav(false)
        setOpenPractice(false)
        setOpenTeamMember(false)
        setOpenOverlay(false)
        setTeamMemberName(null)
      }
    }

    if (reachedBottom && props.isIdeaPage) {
      setBgColour(theme.pilotWhite)
    } else {
      setBgColour(props.bgColour)
    }
  })

  return (
    <>
      <NavigationBar $bgColour={bgColour}>
        <NavigationHamburgerContainer>
          <NavigationHamburger
            aria-label="Open Navigation"
            onClick={toggleOverlay}
          >
            <NavigationHamburgerTopBun
              $isOpen={openOverlay}
              $isNavOpen={openNav}
            />
            <NavigationHamburgerPatty
              $isOpen={openOverlay}
              $isNavOpen={openNav}
            />
            <NavigationHamburgerBottomBun
              $isOpen={openOverlay}
              $isNavOpen={openNav}
            />
          </NavigationHamburger>
        </NavigationHamburgerContainer>

        <NavigationContent initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {label}
        </NavigationContent>
      </NavigationBar>

      <NavigationMenu
        initial={{ x: `100%` }}
        animate={openNav ? "open" : "closed"}
        variants={grandparent}
      >
        <NavigationContainer initial={{ opacity: 0 }} variants={parent}>
          <NavigationHeader>
            <Container>
              <Div $mobileDisplay="none" $tabletDisplay="flex">
                <LogoLinkContainer
                  to="/"
                  onClick={toggleOverlay}
                  aria-label="Pilot PMR"
                >
                  <PilotLogo colour={"#FFFCEF"} />
                </LogoLinkContainer>
              </Div>
            </Container>
          </NavigationHeader>

          <Section
            $touchPaddingTop="2rem"
            $touchPaddingBottom="4rem"
            $mobilePaddingBottom="6rem"
            $desktopPaddingTop="3rem"
          >
            <Container>
              <Div
                $allDisplay="flex"
                $touchFlexDirection="column-reverse"
                $desktopAlignItems="flex-start"
              >
                <Div $desktopWidth="58.3333%">
                  <Div $touchWidth="66.6666%" $desktopWidth="50%">
                    {featuredWork && (
                      <FeaturedWorkCard>
                        <Link
                          to={featuredWork.uri}
                          onClick={toggleOverlay}
                          className="anchor-overlay"
                          aria-label={featuredWork.title}
                        ></Link>

                        <div>
                          <FeaturedWorkCardTitle className="body--small body--secondary">
                            <p>New project</p>
                            <h2 className="headline">{featuredWork.title}</h2>
                          </FeaturedWorkCardTitle>

                          <Div $allDisplay="flex" $allJustifyContent="flex-end">
                            <Div $allWidth="75%">
                              <Animation
                                source={
                                  featuredWork.acfPostTypeWork.navigation.work
                                    .featuredWork.icon.localFile.publicURL
                                }
                              />
                            </Div>
                          </Div>
                        </div>

                        <FeaturedWorkCardBottom className="body--secondary">
                          <FeaturedWorkCardReadLine className="h6">
                            Read the story
                            <span className="icon">
                              <svg
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893L17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7C18 7.26522 17.8946 7.51957 17.7071 7.70711L11.7071 13.7071C11.3166 14.0976 10.6834 14.0976 10.2929 13.7071C9.90237 13.3166 9.90237 12.6834 10.2929 12.2929L14.5858 8L1 8C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6L14.5858 6L10.2929 1.70711C9.90237 1.31658 9.90237 0.683417 10.2929 0.292893Z"
                                  fill="#0A0A0A"
                                />
                              </svg>
                            </span>
                          </FeaturedWorkCardReadLine>
                        </FeaturedWorkCardBottom>
                      </FeaturedWorkCard>
                    )}
                  </Div>
                </Div>
                <Div $touchMarginBottom="4rem" $desktopWidth="41.6666%">
                  <MenuItems>
                    {menu.menuItems.nodes.map(menuItem =>
                      !menuItem.target ? (
                        <MenuItem
                          initial={{ opacity: 0 }}
                          variants={child}
                          key={menuItem.id}
                        >
                          <MenuLink
                            to={menuItem.url}
                            activeClassName="active"
                            onClick={toggleOverlay}
                            aria-label={menuItem.label}
                          >
                            {menuItem.label}
                          </MenuLink>
                        </MenuItem>
                      ) : (
                        <MenuItem
                          initial={{ opacity: 0 }}
                          variants={child}
                          key={menuItem.id}
                        >
                          <MenuLinkTarget
                            href={menuItem.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={toggleOverlay}
                          >
                            {menuItem.label}
                          </MenuLinkTarget>
                        </MenuItem>
                      )
                    )}
                  </MenuItems>
                </Div>
              </Div>
            </Container>
          </Section>
        </NavigationContainer>
      </NavigationMenu>
    </>
  )
}

export default Navigation

const NavigationBar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.theme.navWidth};
  height: 100vh;
  z-index: 15;
  border-left: 1px solid ${props => props.theme.pilotBlack};
  transform: translateX(0);
  transition: background-color 0.35s cubic-bezier(0.1, 0.46, 0.32, 0.9),
    transform 0.35s cubic-bezier(0.1, 0.46, 0.32, 0.9);

  ${maxMQ("mobile")} {
    transform: translateX(80px);
  }

  ${minMQ("tablet")} {
    background-color: ${props => props.$bgColour};
  }
`

const NavigationHamburgerContainer = styled.div`
  min-height: 70px;
  ${maxMQ("touch")} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  ${maxMQ("mobile")} {
    transform: translateX(-80px);
  }

  ${minMQ("desktop")} {
    min-height: 90px;
    padding-top: 1.5rem;
  }
`

const NavigationHamburger = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 29px;
  height: 22px;
  flex-shrink: 0;
  cursor: pointer;
`

const NavigationContent = styled(motion.div)`
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  width: 100%;
`

const NavigationHamburgerTopBun = styled.div`
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.pilotBlack};
  transition: ${props => props.theme.transition};
  transform-origin: center center;
  top: ${props => (props.$isOpen ? "10px" : "0")};
  transform: ${props => (props.$isOpen ? "rotate3d(0, 0, 1, 45deg)" : "none")};

  ${({ $isNavOpen }) =>
    $isNavOpen &&
    css`
      ${maxMQ("mobile")} {
        background-color: ${props => props.theme.pilotWhite};
      }
    `}
`

const NavigationHamburgerPatty = styled.div`
  width: ${props => (props.$isOpen ? "0%" : "100%")};
  height: 2px;
  background-color: ${props =>
    props.$isOpen ? "transparent" : props.theme.pilotBlack};
  transition: ${props => props.theme.transition};
`

const NavigationHamburgerBottomBun = styled.div`
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.pilotBlack};
  transition: ${props => props.theme.transition};
  transform-origin: center center;
  bottom: ${props => (props.$isOpen ? "10px" : "0")};
  transform: ${props => (props.$isOpen ? "rotate3d(0, 0, 1, -45deg)" : "none")};

  ${({ $isNavOpen }) =>
    $isNavOpen &&
    css`
      ${maxMQ("mobile")} {
        background-color: ${props => props.theme.pilotWhite};
      }
    `}
`

const NavigationLabel = styled.div`
  position: relative;
  text-align: right;
  writing-mode: vertical-rl;
`

const NavigationLabelParent = styled(Link)`
  font-family: ${props => props.theme.fontFamilySecondary};
  text-decoration-color: transparent;

  &:hover {
    text-decoration-color: inherit;
  }
`

const NavigationLabelDivider = styled.span`
  margin: 0.5rem 0;
`

const NavigationLabelPage = styled.span`
  font-family: ${props => props.theme.fontFamilyHeading};
  font-weight: 500;
`

const NavigationMenu = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: ${props => props.theme.pilotBlack};
  top: 70px;
  right: 0;
  z-index: 10;
  overflow-y: auto;

  ${minMQ("tablet")} {
    height: 100vh;
    top: 0;
  }
`

const NavigationContainer = styled(motion.div)`
  ${minMQ("tablet")} {
    max-width: calc(100% - (${props => props.theme.navWidth}));
  }
`

const NavigationHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 70px;

  ${maxMQ("touch")} {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${props => props.theme.pilotBlack};
  }

  ${maxMQ("mobile")} {
    display: none;
  }

  ${minMQ("desktop")} {
    min-height: 90px;
  }
`

const LogoLinkContainer = styled(Link)`
  display: inline-flex;
  ${maxMQ("touch")} {
    max-width: ${props => props.theme.logoWidthMobile};
  }
  ${minMQ("desktop")} {
    max-width: ${props => props.theme.logoWidthDesktop};
  }
  > * {
    width: 100%;
    height: auto;
  }
`

const MenuItems = styled.ul`
  display: inline-flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`

const MenuItem = styled(motion.li)`
  display: inline-block;
  color: ${props => props.theme.pilotWhite};

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`

const MenuLink = styled(Link)`
  text-decoration: none;
  font-family: ${props => props.theme.fontFamilyPrimary};
  margin: 0;
  line-height: 1.2;
  ${maxMQ("mobile")} {
    font-size: 42px;
  }
  ${minMQ("tablet")} {
    font-size: 58px;
  }

  ${MenuItems}:hover ${MenuItem} & {
    opacity: 0.3;
  }

  ${MenuItems}:hover ${MenuItem}:hover & {
    opacity: 1;
  }
`

const MenuLinkTarget = styled.a`
  text-decoration: none;
  font-family: ${props => props.theme.fontFamilyPrimary};
  margin: 0;
  line-height: 1.2;
  ${maxMQ("mobile")} {
    font-size: 42px;
  }
  ${minMQ("tablet")} {
    font-size: 58px;
  }

  ${MenuItems}:hover ${MenuItem} & {
    opacity: 0.3;
  }

  ${MenuItems}:hover ${MenuItem}:hover & {
    opacity: 1;
  }
`

const FeaturedWorkCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.pilotWhite};
  border-radius: ${props => props.theme.borderRadiusDesktop};
  ${minMQ("desktop")} {
    min-height: 475px;
    left: 20%;
  }
`

const FeaturedWorkCardTitle = styled.div`
  ${maxMQ("touch")} {
    padding: 1.5rem ${props => props.theme.paddingMobile} 0;
  }
  ${minMQ("desktop")} {
    padding: 1.5rem 2rem 0;
  }

  * {
    margin: 0;
  }
`

const FeaturedWorkCardBottom = styled.div`
  ${maxMQ("touch")} {
    padding: 0 ${props => props.theme.paddingMobile} 1.5rem;
  }
  ${minMQ("desktop")} {
    padding: 0 2rem 1.5rem;
  }
  * {
    margin: 0;
  }
`

const FeaturedWorkCardReadLine = styled.p`
  .icon {
    margin-left: 0.8rem;
  }
`
