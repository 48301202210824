import { createGlobalStyle } from "styled-components"
import { minMQ, maxMQ } from "./helpers"

export const GlobalStyle = createGlobalStyle`
  .clearfix:after { visibility: hidden; display: block; font-size: 0; content: ''; clear: both; height: 0; }

  * { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

  .visuallyhidden:not(:focus):not(:active) { position: absolute; width: 1px; height: 1px; margin: -1px; border: 0; padding: 0; white-space: nowrap; clip-path: inset(100%); clip: rect(0 0 0 0); overflow: hidden; }

  html {
    font-size: ${props => props.theme.fontSize};
  }

  body {
    position: relative;
    font-family: ${props => props.theme.fontFamilyPrimary};
    font-weight: 300;
    line-height: ${props => props.theme.lineHeight};
    color: ${props => props.theme.pilotBlack};
    background-color: ${props => props.theme.pilotWhite};
    transition: 0.35s background-color cubic-bezier(0.1, 0.46, 0.32, 0.9);
    ${maxMQ("mobile")} {
      font-size: 16px;
    }
    ${minMQ("tablet")} {
      font-size: 19px;
    }
    ${minMQ("desktop")} {
      font-size: 22px;
    }
  }

  img,
  video {
    max-width: 100%;
    display: block;
  }

  svg {
    path,
    circle,
    polygon,
    line {
      transition: ${props => props.theme.transition};
    }
  }

  a,
  button {
    transition: ${props => props.theme.transition};
  }

  a,
  a:visited {
    color: inherit;
  }

  a {
    &:hover {
      ${minMQ("desktop")} {
        text-decoration-color: transparent;
      }
    }

    &.reverse {
      text-decoration-color: transparent;
      &:hover {
        ${minMQ("desktop")} {
          text-decoration-color: inherit;
        }
      }
    }
  }

  p {
    &.reverse {
      a {
        text-decoration-color: transparent;
        &:hover {
          ${minMQ("desktop")} {
            text-decoration-color: inherit;
          }
        }
      }
    }
  }

  strong {
    font-weight: bold !important;
  }

  h1,
  h2,
  h3,
  h4, 
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4, 
  .h5,
  .h6 {
    font-family: ${props => props.theme.fontFamilyHeading};
    font-weight: 500;
  }

  h1,
  .h1 {
    line-height: 1.1;
    ${maxMQ("mobile")} {
      font-size: 26px;
    }
    ${minMQ("tablet")} {
      font-size: 31px;
    }
    ${minMQ("desktop")} {
      font-size: 36px;
    }
  }

  h2,
  .h2 {
    line-height: 1.1;
    ${maxMQ("mobile")} {
      font-size: 24px;
    }
    ${minMQ("tablet")} {
      font-size: 27px;
    }
    ${minMQ("desktop")} {
      font-size: 30px;
    }
  }

  h3,
  .h3 {
    ${maxMQ("mobile")} {
      font-size: 22px;
    }
    ${minMQ("tablet")} {
      font-size: 23px;
    }
    ${minMQ("desktop")} {
      font-size: 24px;
    }
  }

  h4,
  .h4 {
    ${maxMQ("mobile")} {
      font-size: 20px;
    }
    ${minMQ("tablet")} {
      font-size: 21px;
    }
    ${minMQ("desktop")} {
      font-size: 22px;
    }
  }

  h5,
  .h5 {
    ${maxMQ("mobile")} {
      font-size: 18px;
    }
    ${minMQ("tablet")} {
      font-size: 19px;
    }
    ${minMQ("desktop")} {
      font-size: 20px;
    }
  }

  h6,
  .h6 {
    ${maxMQ("mobile")} {
      font-size: 16px;
    }
    ${minMQ("tablet")} {
      font-size: 17px;
    }
    ${minMQ("desktop")} {
      font-size: 18px;
    }
  }

  .headline {
    font-family: ${props => props.theme.fontFamilyPrimary};
    margin: 0;
    line-height: 1.2;
    ${maxMQ("mobile")} {
      font-size: 32px !important;
    }
    ${minMQ("tablet")} {
      font-size: 45px !important;
    }
    ${minMQ("desktop")} {
      font-size: 58px !important;
    }
  }

  .body--xlarge {
    >*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
      ${maxMQ("mobile")} {
        font-size: 20px;
      }
      ${minMQ("tablet")} {
        font-size: 28px;
      }
      ${minMQ("desktop")} {
        font-size: 36px;
      }
    }
  }

  .body--large {
    >*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
      ${maxMQ("mobile")} {
        font-size: 16px;
      }
      ${minMQ("tablet")} {
        font-size: 24px;
      }
      ${minMQ("desktop")} {
        font-size: 32px;
      }
    }
  }

  .body--small {
    >*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
      ${maxMQ("mobile")} {
        font-size: 16px;
      }
      ${minMQ("tablet")} {
        font-size: 16px;
      }
      ${minMQ("desktop")} {
        font-size: 16px;
      }
    }
  }

  .body--secondary {
    >*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
      font-family: ${props => props.theme.fontFamilySecondary};
    }
  }

  .body--article {
    >*:first-child {
			margin-top: 0 !important;
		}

		>*:last-child {
			margin-bottom: 0 !important;
		}
  }

  .full-image-container {
    position: relative;
    overflow: hidden;
  }

  .full-image {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    > img {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-object-fit: cover;
      -moz-object-fit: cover;
      -ms-object-fit: cover;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .full-video-container {
    position: relative;
    overflow: hidden;
    video {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-object-fit: cover;
      -moz-object-fit: cover;
      -ms-object-fit: cover;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    video[poster] {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-object-fit: cover;
      -moz-object-fit: cover;
      -ms-object-fit: cover;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    iframe,
    object,
    embed,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  .anchor-overlay {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1;
  }

  .no-scroll {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .wp-block-separator {
    border-bottom: 1px solid #00000066 !important;

    &:not(.is-style-wide):not(.is-style-dots) {
      ${maxMQ("touch")} {
        width: calc(100% - (${props =>
          props.theme.paddingTablet} * 2)) !important;
      }
      ${maxMQ("mobile")} {
        width: calc(100% - (${props =>
          props.theme.paddingMobile} * 2)) !important;
      }
      ${minMQ("desktop")} {
        width: 100% !important;
      }
    }
  }

  .wp-block-embed {
    &__wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      border-radius: ${props => props.theme.borderRadiusDesktop};

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .wp-block-image {
    &:not(.alignfull) {
      overflow: hidden;
      border-radius: ${props => props.theme.borderRadiusDesktop};
    }
  }

  .wp-block-button {
    &__link {
      display: inline-flex;
      align-items: center;
      font-family: ${props => props.theme.fontFamilySecondary};
      font-size: 18px;
      background-color: ${props => props.theme.pilotBlack};
      border: 1px solid ${props => props.theme.pilotBlack};
      border-radius: 0;
      color: ${props => props.theme.pilotWhite} !important;
      text-decoration: none;
      padding: 0.5rem 1.2rem;

      &:hover {
        ${minMQ("desktop")} {
          background-color: transparent;
          color: ${props => props.theme.pilotBlack} !important;
        }
      }
    }

    &.is-style-fill {
      .wp-block-button {
        &__link {
          display: inline-flex;
          align-items: center;
          font-family: ${props => props.theme.fontFamilySecondary};
          font-size: 18px;
          background-color: ${props => props.theme.pilotBlack};
          border: 1px solid ${props => props.theme.pilotBlack};
          border-radius: 0;
          color: ${props => props.theme.pilotWhite} !important;
          text-decoration: none;
          padding: 0.5rem 1.2rem;
    
          &:hover {
            ${minMQ("desktop")} {
              background-color: transparent;
              color: ${props => props.theme.pilotBlack} !important;
            }
          }
        }
      }
    }

    &.is-style-outline {
      .wp-block-button {
        &__link {
          display: inline-flex;
          align-items: center;
          font-family: ${props => props.theme.fontFamilySecondary};
          font-size: 18px;
          background-color: transparent;
          border: 1px solid ${props => props.theme.pilotBlack};
          color: ${props => props.theme.pilotBlack} !important;
          text-decoration: none;
          padding: 0.5rem 1.2rem;
                
          &:hover {
            ${minMQ("desktop")} {
              background-color: ${props => props.theme.pilotBlack};
              color: ${props => props.theme.pilotWhite} !important;
            }
          }
        }
      }
    }
  }

  .gatsby-image-wrapper {
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`
