import React, { useContext, useState, useEffect } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import PilotLogo from "./logo"

import { theme } from "../styles/theme"
import { minMQ, maxMQ } from "../styles/helpers"
import { Container, Div } from "../styles/layout"

import { GlobalContext } from "../../GlobalContext"

const Header = props => {
  const [bgColour, setBgColour] = useState(theme.pilotWhite)
  const [reachedBottom, setReachedBottom] = useState(false)
  const { openNav } = useContext(GlobalContext)

  useEffect(() => {
    if (typeof document !== `undefined`) {
      const handleScroll = () => {
        const offsetHeight = document.documentElement.offsetHeight
        const innerHeight = window.innerHeight
        const scrollTop = document.documentElement.scrollTop

        const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 520

        setReachedBottom(hasReachedBottom)
      }

      window.addEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (reachedBottom && props.isIdeaPage) {
      setBgColour(theme.pilotWhite)
    } else {
      setBgColour(props.bgColour)
    }
  })

  return (
    <MainHeader
      $isIdeaPage={props.isIdeaPage}
      $isNavOpen={openNav}
      $bgColour={bgColour}
    >
      <LogoContainer>
        <Container>
          <Div $allDisplay="flex">
            <PilotLogoLink to="/" aria-label="Pilot PMR">
              <PilotLogo isNavOpen={openNav} />
            </PilotLogoLink>
          </Div>
        </Container>
      </LogoContainer>
    </MainHeader>
  )
}

export default Header

const MainHeader = styled.header`
  position: relative;
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  transition: background-color 0.35s cubic-bezier(0.1, 0.46, 0.32, 0.9);

  ${maxMQ("mobile")} {
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${props => props.theme.pilotWhite};
    z-index: 11;
  }

  ${minMQ("tablet")} {
    max-width: calc(100% - (${props => props.theme.navWidth}));
  }

  ${minMQ("desktop")} {
    min-height: 90px;
  }

  ${({ $isNavOpen }) =>
    $isNavOpen &&
    css`
      ${maxMQ("mobile")} {
        background-color: ${props => props.theme.pilotBlack} !important;
      }
    `}

  ${({ $isIdeaPage }) =>
    $isIdeaPage &&
    css`
      ${maxMQ("touch")} {
        background-color: ${props => props.$bgColour};
      }
      ${minMQ("tablet")} {
        background-color: ${props => props.$bgColour};
      }
    `}
`

const LogoContainer = styled.div`
  width: 100%;
`

const PilotLogoLink = styled(Link)`
  display: inline-flex;

  ${maxMQ("touch")} {
    max-width: ${props => props.theme.logoWidthMobile};
    height: 32px;
  }

  ${minMQ("desktop")} {
    max-width: ${props => props.theme.logoWidthDesktop};
    height: 46px;
  }

  > * {
    width: 100%;
    height: auto;
  }
`
