import React from "react"
import styled, { css } from "styled-components"

import { maxMQ } from "../styles/helpers"

const PilotLogo = props => {
  return (
    <svg
      width="120"
      height="46"
      viewBox="0 0 120 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M35.1338 15.0558C35.1338 22.5848 29.3627 29.1093 18.9481 29.1093H8.53352V45.1695H0V1.00464H17.9436C30.3648 1.00464 35.1338 7.52916 35.1338 15.0582V15.0558ZM26.6027 15.3069C26.6027 11.0402 23.7171 8.53129 18.8865 8.53129H8.53589V22.0826H18.8865C23.7171 22.0826 26.6027 19.133 26.6027 15.3069Z"
        fill={props.colour ? props.colour : "#0A0A0A"}
        $isNavOpen={props.isNavOpen}
      />
      <Path
        d="M51.8297 4.44442L39.6746 8.80615L40.2315 10.3582L52.3866 5.99642L51.8297 4.44442Z"
        fill={props.colour ? props.colour : "#0A0A0A"}
        $isNavOpen={props.isNavOpen}
      />
      <Path
        d="M48.1807 12.0444H40.1494V45.1693H48.1807V12.0444Z"
        fill={props.colour ? props.colour : "#0A0A0A"}
        $isNavOpen={props.isNavOpen}
      />
      <Path
        d="M62.7339 0V45.1694H54.7026V0H62.7339Z"
        fill={props.colour ? props.colour : "#0A0A0A"}
        $isNavOpen={props.isNavOpen}
      />
      <Path
        d="M99.8719 28.6069C99.8719 38.017 93.6601 45.6716 83.3095 45.6716C72.9589 45.6716 66.7471 38.017 66.7471 28.6069C66.7471 19.1968 72.9589 11.5422 83.3095 11.5422C93.6601 11.5422 99.8719 19.1968 99.8719 28.6069ZM91.8406 28.6069C91.8406 23.2125 88.7655 18.8201 83.3095 18.8201C77.8534 18.8201 74.7783 23.2125 74.7783 28.6069C74.7783 34.0014 77.8534 38.3937 83.3095 38.3937C88.7655 38.3937 91.8406 34.0014 91.8406 28.6069Z"
        fill={props.colour ? props.colour : "#0A0A0A"}
        $isNavOpen={props.isNavOpen}
      />
      <Path
        d="M110.964 19.0714V34.191C110.964 37.83 111.028 38.1427 114.416 38.1427H120V45.1695H112.722C106.072 45.1695 102.935 42.9733 102.935 35.006V3.01123H110.966V12.0446H120V19.0714H110.966H110.964Z"
        fill={props.colour ? props.colour : "#0A0A0A"}
        $isNavOpen={props.isNavOpen}
      />
    </svg>
  )
}

export default PilotLogo

const Path = styled.path`
  ${({ $isNavOpen }) =>
    $isNavOpen &&
    css`
      ${maxMQ("mobile")} {
        fill: ${props => props.theme.pilotWhite};
      }
    `}
`
