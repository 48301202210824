import { theme } from "./theme"

const minBreakpoints = {
  tablet: `${theme.sizeTablet}`,
  desktop: `${theme.sizeDesktop}`,
  widescreen: `${theme.sizeWidescreen}`,
  fullhd: `${theme.sizeFullHD}`,
}

const maxBreakpoints = {
  mobile: `${minBreakpoints.tablet - 1}`,
  touch: `${minBreakpoints.desktop - 1}`,
  until_widescreen: `${minBreakpoints.widescreen - 1}`,
  until_fullhd: `${minBreakpoints.fullhd - 1}`,
}

export const minMQ = n => {
  const minBpArray = Object.keys(minBreakpoints).map(key => [
    key,
    minBreakpoints[key],
  ])

  const [result] = minBpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`]
    return acc
  }, [])

  return result
}

export const maxMQ = n => {
  const maxBpArray = Object.keys(maxBreakpoints).map(key => [
    key,
    maxBreakpoints[key],
  ])

  const [result] = maxBpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (max-width: ${size}px)`]
    return acc
  }, [])

  return result
}
