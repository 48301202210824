import React, { createContext } from "react"

// First, we have to prepare some data and functions, that we're later gonna use
// in our reducer

const initialState = {
  openOverlay: false,
  openNav: false,
  openPractice: false,
  openTeamMember: false,
  openNewBusiness: false,
  teamMemberName: null,
}

const actions = {
  SET_OVERLAY: "SET_OVERLAY",
  SET_NAV: "SET_NAV",
  SET_PRACTICE: "SET_PRACTICE",
  SET_TEAM_MEMBER: "SET_TEAM_MEMBER",
  SET_NEW_BUSINESS: "SET_NEW_BUSINESS",
  SET_TEAM_MEMBER_NAME: "SET_TEAM_MEMBER_NAME",
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_OVERLAY:
      return { ...state, openOverlay: action.value }
    case actions.SET_NAV:
      return { ...state, openNav: action.value }
    case actions.SET_PRACTICE:
      return { ...state, openPractice: action.value }
    case actions.SET_TEAM_MEMBER:
      return { ...state, openTeamMember: action.value }
    case actions.SET_NEW_BUSINESS:
      return { ...state, openNewBusiness: action.value }
    case actions.SET_TEAM_MEMBER_NAME:
      return { ...state, teamMemberName: action.value }
    default:
      return state
  }
}

// Then we are creating a context out of the scope of the function
// "GlobalContextProvider" so that we can export it
// and use it in our pages

const GlobalContext = createContext()

const GlobalContextProvider = ({ children }) => {
  // Within our overarching component, we now create a React-Reducer with the data that
  // we've prepared. This gives us a current state and a dispatch function to
  // invoke an action of the reducer function declared earlier.

  const [state, dispatch] = React.useReducer(reducer, initialState)

  // Equipped with "state" and "dispatch" we can now create a value-object, that goes
  // into our ContextProvider.

  const value = {
    openOverlay: state.openOverlay,
    openNav: state.openNav,
    openPractice: state.openPractice,
    openTeamMember: state.openTeamMember,
    openNewBusiness: state.openNewBusiness,
    teamMemberName: state.teamMemberName,
    setOpenOverlay: value => {
      dispatch({ type: actions.SET_OVERLAY, value })
    },
    setOpenNav: value => {
      dispatch({ type: actions.SET_NAV, value })
    },
    setOpenPractice: value => {
      dispatch({ type: actions.SET_PRACTICE, value })
    },
    setOpenTeamMember: value => {
      dispatch({ type: actions.SET_TEAM_MEMBER, value })
    },
    setOpenNewBusiness: value => {
      dispatch({ type: actions.SET_NEW_BUSINESS, value })
    },
    setTeamMemberName: value => {
      dispatch({ type: actions.SET_TEAM_MEMBER_NAME, value })
    },
  }

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

// The exported GlobalContextProvider will only be used in our layout/index.js,
// while the general GlobalContext can be used by any Page or Component (with
// the help of useContext).

export { GlobalContextProvider as default, GlobalContext }
