import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom/client"
import "normalize.css"
import { styled, ThemeProvider } from "styled-components"
import { theme } from "../styles/theme"
import { GlobalStyle } from "../styles/global-style"
import { motion, AnimatePresence } from "framer-motion"

import Header from "./header"
import Footer from "./footer"
import Navigation from "./navigation"

import { minMQ } from "../styles/helpers"

import GlobalContextProvider from "../../GlobalContext"

function hydrateImages() {
  const doc = document
  const inlineWPimages = Array.from(
    doc.querySelectorAll(`[data-wp-inline-image]`)
  )

  if (!inlineWPimages.length) {
    return
  }

  import(
    /* webpackChunkName: "gatsby-plugin-image" */ `gatsby-plugin-image`
  ).then(mod => {
    inlineWPimages.forEach(image => {
      // usually this is the right element to hydrate on
      const grandParentIsGatsbyImage =
        // @ts-ignore-next-line classList is on HTMLElement
        image?.parentNode?.parentNode?.classList?.contains(
          `gatsby-image-wrapper`
        )

      // but sometimes this is the right element
      const parentIsGatsbyImage =
        // @ts-ignore-next-line classList is on HTMLElement
        image?.parentNode?.classList?.contains(`gatsby-image-wrapper`)

      if (!grandParentIsGatsbyImage && !parentIsGatsbyImage) {
        return
      }

      const gatsbyImageHydrationElement = grandParentIsGatsbyImage
        ? image.parentNode.parentNode
        : image.parentNode

      if (
        image.dataset &&
        image.dataset.wpInlineImage &&
        gatsbyImageHydrationElement
      ) {
        const hydrationData = doc.querySelector(
          `script[data-wp-inline-image-hydration="${image.dataset.wpInlineImage}"]`
        )

        if (hydrationData) {
          const imageProps = JSON.parse(hydrationData.innerHTML)

          // @ts-ignore - TODO: Fix me
          const root = ReactDOM.createRoot(gatsbyImageHydrationElement)
          root.render(React.createElement(mod.GatsbyImage, imageProps))
        }
      }
    })
  })
}

const Layout = ({ data, children, location }) => {
  const [bgColour, setBgColour] = useState("")
  const [pageTitle, setPageTitle] = useState("")
  const [parentTitle, setParentTitle] = useState(null)
  const [parentLink, setParentLink] = useState(null)
  const nodeType = data?.post?.nodeType || ``
  const isHomePage = data?.post?.isFrontPage || false
  const isIdeaPage = nodeType === "Post" ? true : false
  const isNewsPage = nodeType === "News" ? true : false
  const isWorkPage = nodeType === "Work" ? true : false
  const isCategoryPage = nodeType === "Category" ? true : false
  const isServicePage = nodeType === "Service" ? true : false
  const isIndustryPage = nodeType === "Industry" ? true : false
  const isTeamMemberPage = nodeType === "TeamMember" ? true : false
  const isJobPage = nodeType === "Job" ? true : false

  useEffect(() => {
    setBgColour(theme.pilotWhite)
    setParentTitle(null)
    setPageTitle(
      data?.post?.acfPage?.navigation?.bar?.alternativeTitle ||
        data?.post?.acfPostTypePost?.shortTitleIdea ||
        data?.post?.acfPostTypeNews?.shortTitleNews ||
        data?.post?.title ||
        `Pilot`
    )

    if (isIdeaPage) {
      setBgColour(data?.post?.acfPostTypePost?.backgroundColour)
      setParentTitle(`Ideas`)
      setParentLink(`/ideas/`)
    }

    if (isCategoryPage) {
      setPageTitle(`Ideas`)
    }

    if (isNewsPage) {
      setPageTitle(`News`)
    }

    if (isWorkPage) {
      setBgColour(
        data?.post?.acfPostTypeWork?.navigation?.bar?.backgroundColour
      )
      setParentTitle(`Work`)
      setParentLink(`/work/`)
    }

    if (isServicePage) {
      setPageTitle(`Work`)
    }

    if (isIndustryPage) {
      setPageTitle(`Work`)
    }

    if (isTeamMemberPage) {
      setParentTitle(`About`)
      setParentLink(`/about/`)
    }

    if (isJobPage) {
      setParentTitle(`Careers`)
      setParentLink(`/careers/`)
    }
  })

  return (
    <GlobalContextProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <SiteWrapper>
          <Header isIdeaPage={isIdeaPage} bgColour={bgColour} />
          <Navigation
            location={location}
            isHomePage={isHomePage}
            isIdeaPage={isIdeaPage}
            parentTitle={parentTitle}
            parentLink={parentLink}
            pageTitle={pageTitle}
            bgColour={bgColour}
          />
          <AnimatePresence
            initial={false}
            mode="wait"
            onExitComplete={() => setTimeout(() => hydrateImages(), 100)}
          >
            <Main
              key={location.pathname}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
            >
              {children}
            </Main>
          </AnimatePresence>
          <Footer />
        </SiteWrapper>
      </ThemeProvider>
    </GlobalContextProvider>
  )
}

export default Layout

const SiteWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`

// const Main = styled.main`
const Main = styled(motion.main)`
  position: relative;

  ${minMQ("tablet")} {
    max-width: calc(100% - (${props => props.theme.navWidth}));
  }
`
