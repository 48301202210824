export const theme = {
  sizeTablet: "769",
  sizeDesktop: "1080",
  sizeWidescreen: "1216",
  sizeFullHD: "1480",
  fontSize: "16px",
  fontFamilyPrimary: `"Cooper BT Std", -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
  fontFamilySecondary: `neue-haas-grotesk-text, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
  fontFamilyHeading: `neue-haas-grotesk-display, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
  lineHeight: "1.4",
  paddingMobile: "25px",
  paddingTablet: "25px",
  paddingDesktop: "40px",
  transition: "0.35s all cubic-bezier(0.1, 0.46, 0.32, 0.9)",
  pilotBlack: "#0A0A0A",
  pilotWhite: "#FFFCEF",
  navWidth: "80px",
  border: "1px solid #0A0A0A",
  logoWidthMobile: "80px",
  logoWidthDesktop: "120px",
  borderRadiusDesktop: "16px",
}
