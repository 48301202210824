import React, { useEffect, useRef } from "react"
import lottie from "lottie-web"

const Animation = props => {
  const animationContainer = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: props.source,
    })
  }, [])

  return <div className="animation-container" ref={animationContainer} />
}

export default Animation
